"use client";

import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuToggle,
  Link,
  Button,
  Image,
} from "@nextui-org/react";

import { useState, useEffect, useRef } from "react";
import Banner from "@/components/banner";

const navLinks = [
  {
    name: "Réalisations",
    href: "/#nos-realisations",
  },
  {
    name: "Tarifs",
    href: "/#tarifs",
  },
  {
    name: "Votre projet",
    href: "/#process",
  },
  {
    name: "Avis clients",
    href: "/#avis-clients",
  },
  {
    name: "Recrutement",
    href: "/recrutement",
  },
  {
    name: "À propos",
    href: "/a-propos",
  },
];

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [totalHeaderHeight, setTotalHeaderHeight] = useState(0);
  const [isAtTop, setIsAtTop] = useState(true);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    const updateTotalHeight = () => {
      if (navbarRef.current) {
        const navbarHeight = navbarRef.current.offsetHeight;
        const totalHeight =
          navbarHeight + (isAtTop && isBannerOpen ? bannerHeight : 0);
        setTotalHeaderHeight(totalHeight);
        document.documentElement.style.setProperty(
          "--total-header-height",
          `${totalHeight}px`,
        );
      }
    };

    updateTotalHeight();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateTotalHeight);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateTotalHeight);
    };
  }, [isBannerOpen, bannerHeight, isAtTop]);

  const handleStartProjectClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const startProjectSection = document.getElementById("start-project");
    if (startProjectSection) {
      const viewportHeight = window.innerHeight;
      const elementHeight = startProjectSection.offsetHeight;
      const scrollTo =
        startProjectSection.offsetTop - (viewportHeight - elementHeight) / 2;

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 0);
  }, []);

  const handleBannerHeightChange = (height: number) => {
    setBannerHeight(height);
  };

  const handleBannerCtaClick = () => {
    setIsMenuOpen(false);
    setTimeout(() => {
      const pricingSection = document.getElementById("tarifs");
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const staticClasses = `
    px-3 duration-300 ease-in-out
    xs:px-4 ph:px-24 md:px-48 lg:px-72 xl:px-96
    data-[menu-open=true]:border-b data-[menu-open=true]:border-divider
    data-[menu-open=true]:bg-background/70 data-[menu-open=true]:backdrop-blur-xl
    data-[menu-open=true]:backdrop-saturate-150
  `;

  const dynamicClasses = isAtTop
    ? "bg-transparent backdrop-blur-none backdrop-opacity-0 backdrop-saturate-0 backdrop-filter-none"
    : "-webkit-backdrop-filter border-none border-transparent bg-[#1F1B4B]/30 ph:bg-[hsla(0,0%,100%,.01)]";

  return (
    <>
      <Banner
        onClose={() => setIsBannerOpen(false)}
        onHeightChange={handleBannerHeightChange}
      />

      <Navbar
        ref={navbarRef}
        className={`${staticClasses} ${dynamicClasses}`}
        style={{
          WebkitBackdropFilter: isAtTop ? "none" : "blur(10px)",
        }}
        isBordered={isAtTop}
        shouldHideOnScroll={false}
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
      >
        <NavbarBrand className="z-40 flex h-full items-center">
          <Link
            href="/"
            className="flex h-full flex-col items-center justify-center"
          >
            <Image
              src="/Next_V_Blanc.png"
              alt="Next Generation"
              aria-label="Accueil Next Generation"
              width={100}
              height={49}
              radius="none"
              className="w-[100px] min-w-[100px]"
              onLoad={() => setIsLoaded(true)}
              loading="lazy"
            />
          </Link>
        </NavbarBrand>
        <NavbarContent className="z-40 hidden gap-8 lg:flex" justify="center">
          {navLinks.map((item) => (
            <NavbarItem key={item.name}>
              <Link
                href={item.href}
                className="cursor-pointer text-white hover:text-gray-500"
              >
                {item.name}
              </Link>
            </NavbarItem>
          ))}
        </NavbarContent>
        <NavbarContent justify="end" className="z-40">
          <Button
            className="bg-primary p-3 text-sm font-[500] text-white hover:bg-indigo-400 hover:text-white hover:!opacity-100"
            radius="sm"
            onClick={handleStartProjectClick}
            as={Link}
            href="#start-project"
          >
            Démarrer un projet
          </Button>
        </NavbarContent>
        <NavbarMenuToggle className="z-40 h-[56px] w-[50px] !text-white lg:hidden" />
        <NavbarMenu
          className={`custom-nav-bar flex flex-col justify-between gap-5 py-8 pl-6 pr-4 lg:hidden ${
            isBannerOpen && isAtTop ? "banner-open" : ""
          } customNavbarMenu}`}
          style={
            {
              zIndex: 30,
              top: "var(--total-header-height)",
              height: "calc(100dvh - var(--total-header-height))",
              maxHeight: "calc(100dvh - var(--total-header-height))",
              overflowY: "auto",
            } as React.CSSProperties
          }
        >
          <div className="grid grid-cols-1">
            {navLinks.map((item) => (
              <NavbarItem key={item.name}>
                <Link
                  href={item.href}
                  className="py-2 text-[21px] text-white hover:text-gray-500"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.name}
                </Link>
              </NavbarItem>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center gap-24">
            <p className="text-center text-white">
              Prêt à lancer votre projet ? <br />
              Réservez dès maintenant votre rendez-vous !
            </p>

            <Button
              className="lg:text-normal h-fit w-full whitespace-pre-wrap bg-primary p-3 text-xs font-[500] text-white xs:w-[250px] ph:w-fit md:w-fit"
              radius="sm"
              as={Link}
              href="https://calendly.com/contact-next-generation/30min"
              target="_blank"
            >
              <div className="h-fit w-fit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 15 15"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M4.5 1a.5.5 0 0 1 .5.5V2h5v-.5a.5.5 0 0 1 1 0V2h1.5A1.5 1.5 0 0 1 14 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 12.5v-9A1.5 1.5 0 0 1 2.5 2H4v-.5a.5.5 0 0 1 .5-.5M10 3v.5a.5.5 0 0 0 1 0V3h1.5a.5.5 0 0 1 .5.5V5H2V3.5a.5.5 0 0 1 .5-.5H4v.5a.5.5 0 0 0 1 0V3zM2 6v6.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V6zm5 1.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0M9.5 7a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1m1.5.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m.5 1.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M9 9.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0M7.5 9a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M5 9.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0M3.5 9a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M3 11.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m2.5-.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1m1.5.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m2.5-.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              Réserver un rendez-vous
            </Button>
          </div>
        </NavbarMenu>
      </Navbar>
    </>
  );
};

export default Header;
