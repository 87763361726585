"use client";

import type { IconProps } from "@iconify/react";
import React from "react";
import { Spacer, Image } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import Link from "next/link";

type SocialIconProps = Omit<IconProps, "icon">;

const navLinks = [
  {
    name: "Accueil",
    href: "/",
  },
  {
    name: "Nos Réalisations",
    href: "#nos-realisations",
  },
  {
    name: "Tarifs",
    href: "#tarifs",
  },
  {
    name: "Services",
    href: "#services",
  },
  {
    name: "Avis clients",
    href: "#avis-clients",
  },
  {
    name: "A Propos",
    href: "#team",
  },
  {
    name: "Contact",
    href: "#formulaire",
  },
  {
    name: "Demander un devis",
    href: "#formulaire",
  },
];

const socialItems = [
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/104688952",
    icon: (props: SocialIconProps) => (
      <Link
        href="https://www.linkedin.com/company/104688952"
        passHref
        legacyBehavior
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-1"
        >
          <Icon {...props} icon="simple-icons:linkedin" />
          <p className="text-md text-white">LinkedIn</p>
        </a>
      </Link>
    ),
  },
];

export default function Footer() {
  const handleContactClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const changeTabEvent = new CustomEvent("changeTab", {
      detail: { tab: "contact", service: "" },
    });
    window.dispatchEvent(changeTabEvent);

    setTimeout(() => {
      const formsSection = document.getElementById("formulaire");
      if (formsSection) {
        formsSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleDevisClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const changeTabEvent = new CustomEvent("changeTab", {
      detail: { tab: "devis", service: "" },
    });
    window.dispatchEvent(changeTabEvent);

    setTimeout(() => {
      const formsSection = document.getElementById("formulaire");
      if (formsSection) {
        formsSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <footer className="flex w-full flex-col">
      <div className="mx-auto w-full max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="mt-4 flex w-full flex-col items-center justify-center md:order-1 md:mt-0">
          <div className="flex items-center justify-center gap-x-2 py-2">
            <Image
              src="/Next_T_Blanc.png"
              alt="Next Generation"
              width={24}
              radius="none"
              className="max-w-[24px]"
              loading="lazy"
            />
            <h1 className="text-text font-text">Next Generation</h1>
          </div>
          <Spacer y={4} />
          <div className="flex flex-wrap justify-center gap-x-4 gap-y-1">
            {navLinks.map((item) => (
              <Link key={item.name} href={item.href} passHref legacyBehavior>
                <a
                  className="text-white"
                  onClick={
                    item.name === "Contact"
                      ? handleContactClick
                      : item.name === "Demander un devis"
                        ? handleDevisClick
                        : undefined
                  }
                >
                  {item.name}
                </a>
              </Link>
            ))}
          </div>
          <Spacer y={6} />

          <div className="flex items-center justify-center gap-x-2">
            {socialItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.icon({
                  className: "w-5 text-white",
                  "aria-hidden": "true",
                })}
              </React.Fragment>
            ))}
          </div>

          <Spacer y={4} />
          <p className="mt-1 text-center text-small text-white">
            &copy; 2024 Next Generation. Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
}
