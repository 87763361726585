import { Button } from "@nextui-org/react";
import Paragraph from "@/components/custom/paragraph";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useState, useRef, useEffect } from "react";
import { usePathname } from "next/navigation";

interface BannerProps {
  onClose?: () => void;
  onHeightChange?: (height: number) => void;
  onCtaClick?: () => void;
}

const Banner: React.FC<BannerProps> = ({ onClose, onHeightChange }) => {
  const [bannerStatus, setBannerStatus] = useState<boolean>(true);
  const bannerRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();

  useEffect(() => {
    const updateHeight = () => {
      if (bannerRef.current && onHeightChange) {
        onHeightChange(bannerRef.current.offsetHeight);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, [onHeightChange]);

  const handlePricesClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    const pricesSection = document.getElementById("tarifs");
    if (pricesSection) {
      const headerHeight = document.querySelector("header")?.offsetHeight || 0;
      const scrollTo = pricesSection.offsetTop - headerHeight - 16; // 20px extra padding

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  if (!bannerStatus || pathname !== "/") return null;

  return (
    <div
      ref={bannerRef}
      className="banner-height flex w-full items-center gap-x-3 px-6 py-2 text-white sm:px-3.5 sm:before:flex-1"
      style={{
        background:
          "linear-gradient(135deg, rgba(59, 130, 246, 0.7) 0%, rgba(167, 139, 250, 0.7) 100%)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Paragraph
        text="🚀 Propulsez votre business : votre site personnalisé à partir de 80€/mois."
        cta="Cliquez ici"
        link="#tarifs"
        addStyle="text-white text-center sm:text-start"
        onCtaClick={handlePricesClick}
      />

      <Button
        className="group relative hidden h-9 overflow-hidden bg-slate-100 px-3.5 text-small font-normal text-black sm:flex"
        color="default"
        endContent={
          <Icon
            className="flex-none outline-none transition-transform group-data-[hover=true]:translate-x-0.5 [&>path]:stroke-[2]"
            icon="solar:arrow-right-linear"
            width={16}
          />
        }
        onClick={handlePricesClick}
      >
        Explorer
      </Button>
      <div className="flex flex-1 justify-end">
        <Button
          isIconOnly
          aria-label="Close Banner"
          className="-m-1"
          size="sm"
          variant="light"
          onClick={() => {
            setBannerStatus(false);
            onClose?.();
          }}
        >
          <Icon
            aria-hidden="true"
            className="text-gray-400"
            icon="lucide:x"
            width={20}
          />
        </Button>
      </div>
    </div>
  );
};

export default Banner;
