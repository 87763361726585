import(/* webpackMode: "eager" */ "/vercel/path0/app/components/layout/footer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/layout/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/scss/_index.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/meta/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/SFProDisplay-BlackItalic.woff2\",\"weight\":\"900\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/SFProDisplay-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/SFProDisplay-HeavyItalic.woff2\",\"weight\":\"900\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/SFProDisplay-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/SFProDisplay-LightItalic.woff2\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/SFProDisplay-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/SFProDisplay-UltralightItalic.woff2\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/SFProDisplay-SemiboldItalic.woff2\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/SFProDisplay-ThinItalic.woff2\",\"weight\":\"100\",\"style\":\"italic\"}],\"display\":\"swap\",\"variable\":\"--font-sf-pro-display\"}],\"variableName\":\"sfProDisplay\"}");
