import { FC, MouseEvent } from "react";

interface ParagraphProps {
  text: string;
  cta: string;
  link: string;
  addStyle?: string;
  onCtaClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const Paragraph: FC<ParagraphProps> = ({
  text,
  cta,
  link,
  addStyle,
  onCtaClick,
}) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (onCtaClick) {
      e.preventDefault();
      onCtaClick(e);
    }
  };

  return (
    <>
      <p className={`text-sm ${addStyle}`}>
        {text}{" "}
        <span className="whitespace-nowrap underline sm:hidden">
          <a href={link} onClick={handleClick}>
            {cta}
          </a>
        </span>
      </p>
    </>
  );
};

export default Paragraph;
